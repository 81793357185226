class Queue {

    constructor({type, date, time, density, user, ...params}) {
        this.type = type
        this.date = date
        this.time = time
        this.density = density
        this.user = user
        this.params = params
    }    

}

export default Queue