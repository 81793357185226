import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import { faker } from '@faker-js/faker';
import { useMemo } from 'react';
import Iconify from '../components/iconify';
import Queue from '../models/queue';
import { QueueWidget } from '../sections/queue';
import { densityColors } from '../constants'
// ----------------------------------------------------------------------

// DEBUG
const max = 30
console.log('densityColors', densityColors);
const Queues = Array(10).fill().map(_ => {

  const order = faker.datatype.number({
    min: 0,
    max
  });

  return new Queue({
    type: 'EMOM',
    date: faker.date.recent().toISOString(),
    time: faker.date.recent().toTimeString(),
    density: Math.floor((densityColors.length - 1) * order / max)
  })
})
//

export default function QueuePage() {

  const queuelist =  useMemo(() => {

    if (!Queues.length) return <>ยังไม่มีคิวที่จองไว้</>
    return Queues.map((q, index) => (
      <Grid key={index} item xs={12} sm={6} md={3}>
        <QueueWidget title={q.type} {...q} />
      </Grid>
    ))
  }, [Queues])

  return (
    <>
      <Helmet>
        <title> จองคิว </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            จองคิว
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            จองคิวใหม่
          </Button>
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          {/* <BlogPostsSearch posts={POSTS} />
          <BlogPostsSort options={SORT_OPTIONS} /> */}
        </Stack>

        <Grid container spacing={3}>
          {queuelist}
        </Grid>
        
      </Container>
    </>
  );
}
