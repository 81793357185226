export const times = [
    {
        time: '0600',
        capacity: 30,
        days: [1, 2, 3, 4, 5]
    },
    {
        time: '0700',
        capacity: 30,
        days: [1, 2, 3, 4, 5]
    }
]

export const densityColors = [
    '#9CE08B',
    '#FAEFA7',
    '#FD7E89',
    '#978FA8'
]
